<template>
    <div class="scroll-container">
        <ul class="left">
            <li v-for="(item, index) in list1" :key="index + 299"><img :src="item.image" /></li>
            <li v-for="(item, index) in list1" :key="index + 99"><img :src="item.image" /></li>
        </ul>

        <div class="right">
            <ul ref="one">
                <li v-for="(item, index) in list2" :key="index + 599"><img :src="item.image" /></li>
            </ul>

            <ul style="transform: translateX(-210%);">
                <li v-for="(item, index) in list2" :key="index + 599"><img :src="item.image" /></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "DualScroll",
    props: {
        list1: {
            type: Array,
            default: () => []
        },
        list2: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            whts:0
        };
    },
    methods: {

    },
    mounted() {
    }
};
</script>

<style lang="scss" scoped>
.scroll-container {
    overflow: hidden;
    width: 100%;

    >ul {
        width: 100%;
        white-space: nowrap;
        height: 40%;
        display: flex;

        >li {
            list-style: none;
            float: left;
            padding-right: 1%;
            box-sizing: border-box;

            >img {
                aspect-ratio: 1.4/1;
                width: 16vw;
                border-radius: 0.6vw;
            }
        }
    }

    .left {
        animation: lefts 50s 3s linear infinite;
        transform: translateX(0%);
    }

    @keyframes lefts {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(-185%);
        }
    }

    .right {
        margin-top: 3%;
        animation: rights 50s 3s linear infinite;
        transform: translateX(0%);
        display: flex;
        >ul {
            white-space: nowrap;
            display: flex;
            padding-left: 1%;

            >li {
                list-style: none;
                float: left;
                padding-right: 1%;
                box-sizing: border-box;

                >img {
                    aspect-ratio: 1.4/1;
                    width: 16vw;
                    border-radius: 0.6vw;
                }
            }
        }
    }

    @keyframes rights {
        0% {
            transform: translateX(0%);
        }

        100% {
            transform: translateX(185%);
        }
    }
}
</style>