<template>
    <div class="wenzhang">
        <div class="wen-title">
            <span>新闻与活动</span>
            <div>成为全球客户和合作伙伴最幸福的商业生态环境</div>
        </div>

        <div class="wen-content">
            <div class="wen-left"><span>新闻</span>
                <div>
                    <ul>
                        <li v-for="(item, index) in xin" :key="index" @click="toXinWen(item, xin)">
                            {{ item.title }}
                            <div>{{ item.updateDate }}</div>
                            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item.description }}</div>
                        </li>
                        <li v-for="(item, index) in xin" :key="index + 200" @click="toXinWen(item, xin)">
                            {{ item.title }}
                            <div>{{ item.updateDate }}</div>
                        </li>
                    </ul>
                </div>
                <div>
                    <div :class="{ acc: titIndex == 0 }" @click="changeIn(0)">公司新闻</div>
                    <div :class="{ acc: titIndex == 1 }" @click="changeIn(1)">产业新闻</div>
                </div>
            </div>
            <div class="wen-right"><span>活动</span>
                <el-carousel style="height: 100%" height="100%" trigger="click" :interval="2000" arrow="always">
                    <el-carousel-item v-for="item in bannerList" :key="item.id">
                        <img v-lazy="item.image" width="100%" height="100%" alt="" style="cursor: pointer"
                            @click="toHuoDong(item, xin)" />
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <div class="wen-btn">
            <div @click="toCaseMoreSuccess(0)">更多新闻</div>
            <div @click=toCaseMoreSuccess(1)>更多活动</div>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "WenZhang",
    data() {
        return {
            xin: [],
            titIndex: 0,
            bannerList: []
        };
    },
    created() {
        this.changeIn(0)
        this.getBanner()
    },
    methods: {
        ...mapActions(["setTheArticle", "setTheArticleList", "setCaseMore"]),
        toCaseMoreSuccess(val) {
            if (val == 0) {
                const openRoutec = this.$router.resolve({
                    name: "XinWaht",
                    query: { categoryId: 592 },
                });
                window.open(openRoutec.href, "_blank");
            } else {
                const openRoute = this.$router.resolve({
                    name: "XinWaht",
                    query: { categoryId: 595 },
                });
                window.open(openRoute.href, "_blank");
            }
        },
        async changeIn(index) {
            this.titIndex = index
            
            if (index == 0) {
                let res = await this.$api.getComList({
                    categoryId: 592,
                    index: 0,
                    size: 4,
                    type: 16
                })
                this.xin = res.data.list;
            } else {
                let res = await this.$api.getComList({
                    categoryId: 594,
                    index: 0,
                    size: 4,
                    type: 16
                })
                this.xin = res.data.list;
            }
        },
        toXinWen(item, _arr) {
            this.setTheArticle(item);
            this.setTheArticleList(_arr);
            const openRoute = this.$router.resolve({
                name: "ExcellentDesign",
            });
            window.open(openRoute.href, "_blank");
        },
        async getBanner() {
            let res = await this.$api.getComList({
                categoryId: 595,
                index: 0,
                size: 4,
                type: 16
            })
            this.bannerList = res.data.list;
        },
        toHuoDong(item, _arr) {
            this.setTheArticle(item);
            this.setTheArticleList(_arr);
            const openRoute = this.$router.resolve({
                name: "ExcellentDesign",
            });
            window.open(openRoute.href, "_blank");
        },
    },
};
</script>
<style lang="scss" scoped>
.wenzhang {
    color: #333333;
    padding-top: 3%;

    .wen-title {
        text-align: center;
        font-size: 3vw;
        padding-bottom: 5%;

        >span {
            letter-spacing: 10px;
            // font-family: title;
        }

        >div {
            color: #9b9b9b;
            padding-top: 1%;
            font-size: 1vw;
            letter-spacing: 3px;
        }
    }

    .wen-content {
        height: 30vw;
        display: flex;

        .wen-left {
            flex: 1;
            position: relative;
            padding-left: 5%;
            background-color: #fafafa;

            >span {
                background-color: #cdcdcd;
                padding: 1.5% 3%;
                position: absolute;
                top: 0%;
                left: 0%;
            }

            >div:nth-child(2) {
                max-height: 18vw;
                overflow: hidden;
                margin-top: 12%;

                >ul {
                    width: 80%;
                    animation: ddd 10s 2s infinite linear;

                    >li {
                        padding-bottom: 5%;
                        font-weight: bold;
                        font-size: max(14px, 1.2vw);
                        cursor: pointer;

                        &:hover {
                            color: #0e68c7;
                        }

                        >div {
                            user-select: none;
                            color: #9b9b9b;
                            font-size: max(12px, 0.7vw);
                            margin-top: 2%;
                            font-weight: 400;
                        }
                    }

                    &:hover {
                        animation-play-state: paused;
                    }
                }

                @keyframes ddd {
                    0% {
                        transform: translateY(0%);
                    }

                    100% {
                        transform: translateY(-50%);
                    }
                }
            }


            >div:nth-child(3) {
                position: absolute;
                bottom: 3%;
                color: #9b9b9b;
                width: 100%;

                >div {
                    display: inline-block;
                    padding: 0.5% 2%;
                    border: 1px solid #9b9b9b;
                    border-radius: 1vw;
                    cursor: pointer;

                    &:hover {
                        color: white;
                        background-color: #0e68c7;
                        border: 1px solid #0e68c7;
                    }
                }

                .acc {
                    color: white;
                    background-color: #0e68c7;
                    border: 1px solid #0e68c7;
                }

                >div:nth-child(2) {
                    margin-left: 3%;
                }
            }
        }

        .wen-right {
            flex: 1.2;
            position: relative;

            >span {
                background-color: #cdcdcd;
                padding: 1.5% 3%;
                position: absolute;
                top: 0%;
                left: 0%;
                z-index: 100;
            }
        }
    }

    .wen-btn {
        text-align: center;
        padding-top: 8%;
        padding-bottom: 3%;

        >div {
            color: #3c6da0;
            border: 1px solid #3c6da0;
            display: inline-block;
            padding: 0.7% 3.5%;
            cursor: pointer;
            transition: all .3s;

            &:hover {
                background-color: #3c6da0;
                color: white !important;
            }
        }

        >div:nth-child(1) {
            margin-right: 2%;
        }

        >div:nth-child(2) {
            margin-left: 2%;
        }
    }
}

@media screen and (max-width: 600px) {
    .wenzhang {
        .wen-content {
            flex-direction: column;
            height: 100vw;

            .wen-left {
                >div:nth-child(2) {
                    >ul {
                        width: 100%;
                        text-align: center;
                    }
                }

                >div:nth-child(3) {
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;

                    >div {
                        padding: 1% 3%;
                    }
                }
            }
        }
    }
}
</style>