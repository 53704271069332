<template>
    <span class="nu">
        <!-- <span ref="number">{{ displayValue }}</span> -->
        <span :id="value" ></span>
    </span>
</template>
  
<script>
// export default {
//     props: {
//         value: {
//             type: Number,
//             required: true,
//         },
//         duration: {
//             type: Number,
//             default: 5000,
//         },
//     },
//     data() {
//         return {
//             currentValue: 0,
//             targetValue: 0,
//             max:0
//         };
//     },
//     computed: {
//         displayValue() {
//             return Math.floor(this.currentValue);
//         },
//     },
//     watch: {
//         value(newValue) {
//             this.startCounting(newValue);
//         },
//     },
//     methods: {
//         startCounting(targetValue) {
//             this.targetValue = targetValue;
//             const startTimestamp = performance.now();
//             const animate = (timestamp) => {
//                 const timeElapsed = timestamp - startTimestamp;
//                 const progress = Math.min(timeElapsed / this.duration, 1);
//                 this.currentValue = this.easeInOutQuint(
//                     progress,
//                     this.currentValue,
//                     this.targetValue - this.currentValue,
//                     1
//                 );
//                 if (progress < 1) {
//                     requestAnimationFrame(animate);
//                 } else {
//                     this.currentValue = this.targetValue;
//                 }
//             };
//             requestAnimationFrame(animate);
//         },
//         easeInOutQuint(t, b, c, d) {
//             t /= d / 2;
//             if (t < 1) return (c / 2) * t * t * t * t * t + b;
//             t -= 2;
//             return (c / 2) * (t * t * t * t * t + 2) + b;
//         },
//     },
//     // mounted() {
//     //     this.startCounting(this.value);
//     // },

// };

export default {
    props: {
        value: {
            type: String,
        }
    },
    data() {
        return {
            timer: null
        }
    },
    mounted() {
        let num = document.getElementById(this.value)
        this.timer = setInterval(function () {
            num.innerText = 10 + Math.round(Math.random() * 5000);
        }, 70)
    },
    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>
<style>
.nu {
    font-size: 3.5vw;
}
</style>