<template>
    <div class="core-server">
        <div class="con-title">
            <div class=" wow animate__animated animate__fadeInUp">
                核心服务
            </div>
            <div class="title-e wow animate__animated animate__fadeInLeft">
                区域协同 / 跨界融合 / 资源共享
            </div>
        </div>
        <div class="core-content" @click="$router.push({ name: 'CoreServerSon' })">
            <div class="core-content_left wow animate__animated animate__fadeInUp" data-wow-delay="0.3s">
                <div>
                    <div>
                        <div></div>商业展示产业第三方服务
                    </div>
                    <div>Third party services for commercial display industry</div>
                </div>
                <div>
                    <div>
                        <div></div>提供品牌建店系统解决方案
                    </div>
                    <div>Provide brand building system solutions</div>
                </div>
            </div>

            <div class="core-content_right wow animate__animated animate__fadeInUp" data-wow-delay="0.5s">
                <div><img src="../../assets/img/251.png" /></div>
                <div></div>
                <div>
                    <div>▼</div>
                    <div>▼</div>
                    <div>▼</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        };
    },

    mounted() {
        this.$nextTick(() => {
            this.$wow.init();
        });
    },
};
</script>
<style lang="scss" scoped>
.core-server {
    // height: clamp(500px, 45vw, 1500px);
    background-image: url("../../assets/shouyeimg/31.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 5%;
    color: white;
    font-size: max(16px, 3vw);

    .con-title {
        letter-spacing: 5px;
        text-align: center;
        font-size: 3.5vw;
        position: relative;
        margin-bottom: 3%;

        >div:nth-child(1) {
            letter-spacing: 10px;
        }

        .title-e {
            font-size: 1vw;
            color: #cecece;
            padding-top: 1%;
        }
    }

    .core-content {
        width: 80%;
        color: #262626;
        padding: 4% 0%;
        margin: auto;
        gap: 5%;
        font-size: 1vw;
        display: flex;
        background-color: rgba(231, 228, 228, 0.3);
        cursor: pointer;

        .core-content_left {
            flex: 5;
            background-color: white;
            padding: 1% 0%;
            position: relative;

            >div {
                padding: 5%;

                >div:nth-child(1) {
                    font-weight: bold;
                    font-size: 2vw;
                    letter-spacing: 3px;
                    margin-bottom: 1%;
                    color: #414141;
                    position: relative;

                    >div {
                        position: absolute;
                        background-color: #449D4D;
                        height: 15px;
                        width: 15px;
                        top: 50%;
                        left: 0%;
                        transform: translate(-200%, -40%);
                        border-radius: 50%;

                        &::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-10%, -50%);
                            border-top: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            border-left: 5px solid white;
                        }
                    }
                }

                >div:nth-child(2) {
                    color: #3F9643;
                    font-weight: 300;
                    letter-spacing: 3px;
                }
            }

            >div:nth-child(1) {
                position: relative;
                >div:nth-child(1){
                    letter-spacing: 6px;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0%;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    height: 2px;
                    width: 90%;
                    background-color: #3E9E4C;
                }
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0%;
                transform: translate(-100%, -50%);
                height: 100%;
                width: 3.5%;
                background-color: #449D4D;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 0%;
                transform: translate(50%, -50%);
                height: 2.5vw;
                width: 2.5vw;
                background-color: #56BFC3;
            }
        }

        .core-content_right {
            flex: 2;
            display: flex;
            flex-direction: column;

            >div:nth-child(1) {
                flex: 1;
                padding-left: 30%;
                >img {
                    height: 2.5vw;
                }
            }

            >div:nth-child(2) {
                flex: 8;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    height: 90%;
                    width: 2px;
                    background-color: white;
                }
            }

            >div:nth-child(3) {
                flex: 1;
                color: white;
                position: relative;
                text-align: center;
                font-size: 1vw;
            }
        }

    }
}

@media screen and (max-width: 600px) {
    .core-server {
        //  height: auto;
        background-size: cover;
        background-position: center center;

        .core-content {
            width: 95%;
        }
    }
}</style>