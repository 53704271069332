<template>
  <div class="new-settle">
    <div class="new-settle-title">
      <img src="@/assets/img/新入驻企业.png" width="100%" height="100%" alt="">
    </div>
    <ul class="new-settle-img">
      <li v-for="item in 5" :key="item">
        <el-skeleton-item variant="image" style="width: 100%; height: 100%" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.new-settle {
  @include flexbox(row);
  width: $containerWidth;
  margin: 0 auto;
  height: 145px;
  margin-top: 30px;
  margin-bottom: 80px;
  .new-settle-title {
    width: 180px;
    @include flexbox(row, center, center);
    background-color: #9a9a9a;
  }
  .new-settle-img {
    flex: 1;
    overflow: auto;
    @include flexbox(row);
    li {
      flex: 1;
      overflow: auto;
    }
  }
}
</style>