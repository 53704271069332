<template>
    <div class="high-ent">
        <div class="title wow animate__animated animate__fadeInUp">
                <span>服务品牌</span>
            <div class="wow animate__animated animate__fadeInLeft">
                为全球客户和合作伙伴创造长期共赢价值
            </div>
        </div>

        <div class="wraps">
            <Warps :list1="highList1" :list2="highList2"/>
        </div>
    </div>
</template>
<script>
import Warps from './son/Warps.vue'
export default {
    components: {
        Warps,
    },
    data() {
        return {
            listNum: 10, //一个轮播页多少项
            highList1: [],
            highList2: [],
        };
    },
    created() {
        this.getHighList();
    },
    methods: {
        async getHighList() {
            let data1 = await this.$api.getComList({
                categoryId: 597,
                index: 0,
                size: this.listNum,
                type: 16
            })
            let data2 = await this.$api.getComList({
                categoryId: 597,
                index: 1,
                size: this.listNum,
                type: 16
            })

            this.highList1 = [...data1.data.list];
            this.highList2 = [...data2.data.list];

            const newArr = this.highList1.filter(obj => obj.image !== '')
            this.highList1 = newArr

            const newArr1 = this.highList2.filter(obj => obj.image !== '')
            this.highList2 = newArr1
        },
        toComIndex(syscompanyid) {
            const openRoute = this.$router.resolve({
                name: "ComIndex",
                params: { syscompanyid },
            });
            window.open(openRoute.href, "_blank");
        },
    },
};
</script>
<style lang="scss" scoped>
.high-ent {
    padding-top: 10%;
    letter-spacing: 5px;
    position: relative;
    &::after{
        content: '';
        position:absolute;
        background-color: #0f1a44;
        height: 20px;
        width: 100%;
        top: 6%;
        left: 50%;
        transform: translateX(-50%)
    }

    .title {
        text-align: center;
        font-size: 3vw;
        >span{
            // font-family: title;
            letter-spacing: 10px;
        }
        >div {
            font-size: 0.9vw;
            padding-top: 1%;
            color: #b3b2b2;
        }
    }
    .wraps {
        margin: 6% 0%;
        height: 21vw;
    }
}

</style>