<template>
  <div class="purchase-data">
    <div class="data-num" @mouseenter="numberJu">
      <div class="data-num-item">
        <div style="{color: white;}">
          <!-- <Number :value="num1" />+<span class="unit">家</span> -->
          <Number value="num1" />+<span class="unit">例</span>
        </div>
        <div>建店方案</div>
      </div>
      <div class="data-num-item">
        <div style="{color: white;}">
          <Number value="num2" />+<span class="unit">万笔</span>
        </div>
        <div>成交量</div>
      </div>
      <div class="data-num-item">
        <div style="{color: white;}">
          <Number value="num3" />+<span class="unit">家</span>
        </div>
        <div>供应商</div>
      </div>
      <div class="data-num-item">
        <div style="{color: white;}">
          <Number value="num4" />+<span class="unit">条</span>
        </div>
        <div>日均交易</div>
      </div>
    </div>
    <img class="img1" src="../../assets/shouyeimg/11.jpg" />
    <img class="img2" src="../../assets/shouyeimg/12.png" />
  </div>
</template>
<script>
import Number from "./son/Number.vue";
export default {
  components: {
    Number,
  },
  data() {
    return {
      num1:0,
      num2:0,
      num3:0,
      num4:0,
    };
  },
  methods:{
    numberJu(){
      this.num1 = 1860
      this.num2 = 3000
      this.num3 = 1648
      this.num4 = 5489
    }
  }
};
</script>
<style lang="scss" scoped>
.purchase-data {
  // height: clamp(300px,70vw,1500px);
  background-color: #171d27;
  position: relative;
  overflow: hidden;
  color: white;

  .img1,
  .img2 {
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .img1 {
    top: -3%;
    transform: translateX(-50%);
  }

  .img2 {
    height: 44%;
    width: 110%;
    bottom: 0%;
    display: none;
  }

  .data-num {
    position: relative;
    z-index: 100;
    font-size: 1.4vw;
    display: flex;
    gap: 2%;
    height: 28vw;
    // margin-top: 45vw;
    margin-top: 24vw;
    // padding-bottom: 5vw;

    .data-num-item {
      flex: 1;
      text-align: center;
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;
      padding-top: 5%;
      box-sizing: border-box;
      display: none;

      >div:nth-child(1) {
        margin-bottom: 10%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .unit {
          font-size: 1.3vw;
        }
      }
    }

    .data-num-item:nth-child(1) {
      background-image: url(../../assets/shouyeimg/13.png);

      >div {
        padding-left: 6%;
        position: relative;
      }

      >div:nth-child(2) {
        color: #77dbff;
      }
    }

    .data-num-item:nth-child(2) {
      background-image: url(../../assets/shouyeimg/14.png);

      >div {
        padding-left: 6%;
        position: relative;
      }

      >div:nth-child(2) {
        color: #79ff7b;
      }
    }

    .data-num-item:nth-child(3) {
      background-image: url(../../assets/shouyeimg/15.png);

      >div {
        position: relative;
      }

      >div:nth-child(2) {
        color: #ffbb75;
      }
    }

    .data-num-item:nth-child(4) {
      background-image: url(../../assets/shouyeimg/16.png);

      >div {
        position: relative;
      }

      >div:nth-child(2) {
        color: #7bd1ff;
      }
    }
  }

}

@media screen and (max-width:600px) {
  .purchase-data {}
}</style>