<template>
  <page-view class="index" ref="indexRef" :up-class="'index'">
    <div>
      <!-- 首页第一板块：数字化平台 -->
      <purchase-data></purchase-data>
      <!-- 首页第二板块：产业链 -->
      <cooper-brand></cooper-brand>
      <!-- 首页第三板块：核心服务 -->
      <core-server></core-server>
      <!-- 首页第四板块：新闻与活动 -->
      <WenZhang></WenZhang>
      <!-- 首页最后板块：服务品牌 -->
      <high-ent></high-ent>
      <footer-com></footer-com>
    </div>
  </page-view>
</template>
<script>
import CooperBrand from "@/components/index/CooperBrand.vue";
import PurchaseData from "@/components/index/PurchaseData.vue";
import NewSettle from "@/components/index/NewSettle.vue";
import WenZhang from "../../components/index/WenZhang.vue";//首页文章组件
import CoreServer from "@/components/index/CoreServer.vue";
import HighEnt from "@/components/index/HighEnt.vue";
export default {
  name: "Index",
  components: {
    CooperBrand,
    PurchaseData,
    NewSettle,
    WenZhang,
    CoreServer,
    HighEnt,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  deactivated() {},
};
</script>
<style lang="scss" scoped>
.index {
  overflow: auto;
}
</style>