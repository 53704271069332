<template>
    <div class="cooper-brand">
        <div class="title">
            <div class="title-l wow animate__animated animate__fadeInLeft">
                产业生态链
            </div>
            <div class="title-r wow animate__animated animate__fadeInRight">
                赋&nbsp;能&nbsp;品&nbsp;牌&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;共&nbsp;享&nbsp;生&nbsp;态
            </div>
        </div>

        <div id="slideshow">
            <div class="entire-content">
                <div class="bd-bg-center">
                    <img src="../../assets/shouyeimg/qiu.png" width="90%" />
                </div>
                <div class="content-carrousel" ref="rotateRef">
                    <div class="shadow"
                        :style="{ transform: 'rotateY(' + index * (360 / cooperList.length) + 'deg) translateZ(' + zdeg + 'px)', }"
                        v-for="(item, index) in cooperList" :key="index" @click="clickCooper(item)">
                        <img v-if="item.logo" class="logo-img" :src="item.logo" alt="" />
                        <div v-else style="color:#439C4C;">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="cooper-more" @click="toIndex">查看更多</div>

    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
    name: "CooperBrand",
    data() {
        return {
            cooperList: [],
        };
    },
    created() {
        this.selectSn()
    },
    methods: {
        //获取合作伙伴
        async selectSn() {
            let cooperListRes = await this.$api.selectSn({
                current: 1,
                size: 26,
                s1: 0,
            });
            this.cooperList = cooperListRes.data.slice(0, 26);
        },
        clickCooper(item) {
            const openRoute = this.$router.resolve({
                name: "ComIndex",
                params: { syscompanyid: item.syscompanyid },
            });
            window.open(openRoute.href, "_blank");
        },
        toIndex() {
            const openRoute = this.$router.resolve({
                name: "FamousEnt",
            });
            window.open(openRoute.href, "_blank");
        }
    },
    computed: {
        ...mapState(["screenWidth", "navIndex"]),
        zdeg() {
            if (this.screenWidth > 600) {
                return this.screenWidth / 4.4
            } else {
                return this.screenWidth / 1.5
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.cooper-brand {
    height: clamp(500px, 61vw, 1500px);
    overflow: hidden;
    position: relative;
    color: white;
    font-size: 3vw;
    background-image: url(../../assets/shouyeimg/bg-qiu.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;

    .title {
        text-align: center;
        font-weight: bold;
        position: absolute;
        top: 14%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 500;
        font-size: 3.7vw;

        >div:nth-child(1) {
            font-weight: 500;
            font-size: 3.5vw;
            letter-spacing: 10px;
            // font-family: title;
        }

        >div:nth-child(2) {
            color: #9b9b9b;
            font-size: 1vw;
            font-weight: 500;
            margin-top: 6%;
        }
    }

    .cooper-more {
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        color: #9b9b9b;
        font-size: max(14px,1.3vw);
        font-weight: 500;
        cursor: pointer;
        border-bottom: 1px #9b9b9b solid;
        transition: all .3s;

        &:hover {
            color: #439C4C;
            border-bottom: 1px #439C4C solid;
        }
    }
}
</style>

<style lang="scss" scoped>
#slideshow {
    margin: 0 auto;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    // height: clamp(300px,35vw,1500px);
    width: 100%;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    /*系统默认菜单被禁用*/
    -webkit-user-select: none;
    /*webkit浏览器*/
    -khtml-user-select: none;
    /*早期浏览器*/
    -moz-user-select: none;
    /*火狐*/
    -ms-user-select: none;
    /*IE10*/
    user-select: none;
}

/* // 整个地球仪盒子 */
.entire-content {
    margin: auto;
    width: 5vw;
    height: 5vw;
    perspective: 800px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform-style: preserve-3d;
    transform: translate(-50%, -50%) rotateX(-5deg);
}

/* // 中间圆形球体 */
.bd-bg-center {
    width: clamp(300px, 60vw, 1500px);
    position: absolute;
    top: 300%;
    left: 100%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 1;
}

.bd-bg-center img {
    width: 100%;
    height: 100%;
}

/* // 转动区域 */
.content-carrousel {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 6%;
    left: -11%;
    transform-origin: 50% 50%;
    animation: rotar 58s infinite linear;
    transform-style: preserve-3d;
    transform: translate(-50%, -50%) rotateY(0deg);

}

.content-carrousel>img {
    animation-play-state: paused !important;
}

.content-carrousel:hover {
    animation-play-state: paused;
    cursor: pointer;
}

.content-carrousel:hover .shadow {
    animation-play-state: paused !important;
}


/* // 图片盒子 背景 */
.shadow {
    height: 5vw;
    width: 4vw;
    position: absolute;
    box-shadow: 1px 1px 3px 0px #b3b2b2;
    background-size: 100% 100%;
    text-align: center;
    background-color: rgba($color: #ffffff, $alpha: 1);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width:600px) {
        height: 15vw;
        width: 12vw;
    }
}

/* // 图片下方文字 */
.shadow div {
    width: 100%;
    text-align: center;
    font-size: 12px;
    transform: scale(0.6);
}

.shadow img {
    object-fit: contain;
    flex: 1;
    width: 100%;
    pointer-events: none;
}

/* // 图片里的小图标 */
.content-carrousel div .svg-icon {
    transition: all 300ms;
    width: 40%;
    height: 40%;
    color: #ffffff;
}

.content-carrousel div .svg-icon:hover {
    transform: scale(1.2);
    transition: all 300ms;
}

@keyframes rotar {
    from {
        transform: rotateY(0deg);
    }

    to {
        transform: rotateY(360deg);
    }
}

@-moz-keyframes rotar {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

@-ms-keyframes rotar {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

@-webkit-keyframes rotar {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}
</style>
  